import * as a1lib from "@alt1/base";
import ttreader from "./tooltip";
import * as _ from "lodash";
var md5 = require("md5");
var axios = require('axios');
//tell webpack to add index.html and appconfig.json to output
require("!file-loader?name=[name].[ext]!./tooltip.ts");
require("!file-loader?name=[name].[ext]!./index.html");
require("!file-loader?name=[name].[ext]!./appconfig.json");
var qw = console.log.bind(console);
var reader = new ttreader();
var results = [];
var item_name_regex = /(?<=Withdraw-?(?:\d+)\s)[\w\s]*/i;
var breedRegex = /(?:(?<Zanarian>zan(?:arian)?)|(?<Gloom>gloo(?:mshroom)?)|(?<Daemoncap>dae(?:moncap)?)|(?<Arcspore>arc(?:spore)?)|(?<Cactoad>cact(?:oad)?)|(?<Common>comm(?:on)?)|(?<Gold>gold)|(?<Phantasmal>phan(?:tasmal)?|poison)|(?<Blue>blue)|(?<Sky>\bsky\b)|(?<Azure>azure)|(?<Carnivorous>carn(?:ivorous)?)|(?<Cobalt>cobalt)|(?<Crimson>crim(?:son)?)|(?<Crystal>\bcry(?:stal)?\b)|(?<Grey>grey)|(?<Viridian>viri(?:dian)?)|(?<Corpse>corp(?:se)?)|(?<Fever>fever)|(?<Giant>giant)|(?<Ice>\bice\b)|(?<Night>night)|(?<Spirit>spirit)|(?<Fremennik>frem(?:ennik)?|fremmy)|(?<Amphibious>amph(?:ibious)?)|(?<Aquatic>aqua(?:tic)?)|(?<Camouflaged>camo(?:uflaged)?)|(?<Cannibal>cann(?:ibal)?)|(?<Carrion>carri(?:on)?)|(?<Diseased>disease(?:d)?)|(?<Draconic>drac(?:onic)?)|(?<Igneous>igne(?:ous)?)|(?<Shadow>shad(?:ow)?)|(?<Black>black)|(?<Green>green)|(?<Red>red)|(?<Feral>feral)|(?<Ripper>ripp(?:er)?)|(?<Venomous>veno(?:mous)?)|(?<Base>base)|(?<Iratum>irat(?:um)?)|(?<Natura>natura)|(?<Beach>beach)|(?<Forest>fore(?:st)?)|(?<Orange>orange)|(?<Blavum>blav(?:um)?)|(?<Palus>pal(?:us)?)|(?<Nemus>nem(?:us)?)|(?<Purpura>purp(?:ura)?)|(?<Tilia>tilia)|(?<Acta>acta)|(?<Aurum>aurum)|(?<Gelum>gel(?:um)?)|(?<Malum>malum)|(?<Rex>rex)|(?<Glacies>glaci(?:es)?)|(?<Oceanum>ocean(?:um)?)|(?<Desertum>desert(?:um)?)|(?<Brown>brown)|(?<Piscatorian_cottontail>pisc(?:atorian cottontail)?)|(?<Rellekkan_cream>rell(?:ekkan cream)?)|(?<Bandosian_bantam>bando(?:sian bantam)?)|(?<White>white)|(?<Oomlie>oomlie)|(?<Varrockian_red>varrock(?:ian red)?)|(?<Fallfaced>fall(?:faced)?)|(?<Springsheared>spring(?:sheared)?)|(?<Summerdown>summer(?:down)?)|(?<Winterwold>winter(?:wold)?)|(?<Chocolate>choco(?:late)?)|(?<Kandarin>kand(?:arin)?)|(?<Strawberry>straw(?:berry)?)|(?<Vanilla>vanilla))/i;
var speciesRegex = /(?<Rabbit>Rabbit)|(?<Chicken>Chicken|hen|rooster)|(?<Sheep>Sheep|ewe|ram)|(?<Frog>Frog)|(?<Cow>Cow|bull)|(?<Chinchompa>Chin(?:chompa)?)|(?<Spider>Spider)|(?<Yak>Yak)|(?<Jadinko>Jad(?:inko)?)|(?<Zygomite>Zyg(?:omite)?)|(?<Dragon>Drag(?:on)?)|(?<Varanusaur>Varan(?:usaur)?|veno(?:mous)?|feral|ripp(?:er)?|slay(?:er)?)|(?<Arcane>Arcane)|(?<Brutish>Brut(?:ish)?|beach|fore(?:st)?)|(?<Salamander>Salamander|Sally)|(?<Scimitops>Scim(?:itops)?)|(?<Bagrada>Bag(?:rada)?)|(?<Spicati>Spic(?:ati)?)|(?<Asciatops>Asci(?:atops)?)|(?<Corbicula>Corb(?:icula)?)|(?<Oculi>Oculi)|(?<Malletops>Malle(?:tops)?|Maletops)|(?<Pavosaurus>Pav(?:osaurus)?|pavo)/i;
function get_age(text) {
    switch (true) {
        case ((/egg(?:s)?/i).test(text)):
            return 1;
        case ((/children|child|\bbaby(?:s)?\b|\bbb(?:s)?\b|bby|babies/i).test(text)):
            return 2;
        case ((/Adolescent(?:s)?|Adol(?:s)?/i).test(text)):
            return 3;
        case ((/adult(?:s)?/i).test(text)):
            return 4;
        case ((/Elder(?:s)?/i).test(text)):
            return 5;
        default:
            return 0;
    }
}
var post_to_server = function (json) { return axios.post('https://api.organicdynamic.xyz/livestock', json); };
export function capture() {
    alt1.overLaySetGroup("read_items");
    reader.track(function (tt) {
        var _a, _b;
        if (tt == null)
            return;
        var raw = tt.readInteraction().map(function (obj) { return obj.text; });
        var item_name = ((item_name_regex).test(raw[0])) ? (item_name_regex).exec(raw[0])[0] : "";
        var species_result = (speciesRegex).exec(item_name);
        var breed_result = (breedRegex).exec(item_name);
        qw(species_result);
        if (!species_result)
            return;
        var shiny = (/Shiny!?/i).test(raw[3]);
        var data = {
            mousepos: {
                mouse_x: tt.mousepos.x,
                mouse_y: tt.mousepos.y,
            },
            animal: {
                species: (species_result) ? Object.entries(species_result.groups).filter(function (e) { return e[1]; })[0][0] : null,
                breed: (breed_result) ? Object.entries(breed_result.groups).filter(function (e) { return e[1]; })[0][0] : null,
                name: ((_a = raw[1]) === null || _a === void 0 ? void 0 : _a.replace(/name:\s/i, '')),
                gender: ((_b = raw[2]) === null || _b === void 0 ? void 0 : _b.replace(/gender:\s/i, '')),
                is_shiny: shiny,
                age: get_age(raw[3 + (shiny ? 1 : 0)]),
                trait1: (raw.length > 5) ? raw[5 + (shiny ? 1 : 0)].replace(/trait:\s/i, '') : null,
                trait2: (raw.length > 6) ? raw[6 + (shiny ? 1 : 0)].replace(/trait:\s/i, '') : null,
                trait3: (raw.length > 7) ? raw[7 + (shiny ? 1 : 0)].replace(/trait:\s/i, '') : null
            }
        };
        // qw(data);
        if (results.some(function (element) { return _.isEqual(element.animal, data.animal) && Math.sqrt(Math.pow(Math.abs(element.mousepos.mouse_x - data.mousepos.mouse_x), 2) + Math.pow(Math.abs(element.mousepos.mouse_y - data.mousepos.mouse_y), 2)) < 60; }))
            return;
        alt1.overLayRect(a1lib.mixColor(0, 255, 0), tt.mousepos.x - 10, tt.mousepos.y, 20, 10, 60000, 100);
        results.push(data);
    });
}
export function stop_capture() {
    alt1.overLayClearGroup("read_items");
    reader.stopTrack();
}
export function save() {
    alt1.overLayClearGroup("read_items");
    reader.stopTrack();
    var json = [];
    var animals = results.map(function (x) { return x.animal; });
    var TOKEN = md5((Date.now() + animals[0]));
    json[0] = TOKEN;
    json = json.concat(animals);
    post_to_server(json);
    copyTextToClipboard("/claim " + TOKEN);
    window.open("https://discordapp.com/channels/692224703305089024/720655324045901934");
    results = [];
}
if (window.alt1)
    alt1.identifyAppUrl("./appconfig.json");
// function save_json(json) {
//     var filename = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'untitled';
//     var str = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(json, null, 2));
//     var anchor = window.document.createElement('a');
//     anchor.setAttribute('href', str);
//     anchor.setAttribute('download', filename + '.json');
//     anchor.click();
// };
function copyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
    }
    catch (err) {
        console.log('Oops, unable to copy');
    }
    document.body.removeChild(textArea);
}
