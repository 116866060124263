(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("node-fetch"), require("pngjs"));
	else if(typeof define === 'function' && define.amd)
		define(["node-fetch", "pngjs"], factory);
	else if(typeof exports === 'object')
		exports["testpackage"] = factory(require("node-fetch"), require("pngjs"));
	else
		root["TEST"] = factory(root["node-fetch"], root["pngjs"]);
})((typeof self!='undefined'?self:this), function(__WEBPACK_EXTERNAL_MODULE_node_fetch__, __WEBPACK_EXTERNAL_MODULE_pngjs__) {
return 